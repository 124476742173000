import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classnames from 'classnames'
import cookies from './cookies' // TODO: revert import change when this issue is resolved: https://github.com/streamich/react-use/issues/2074
import useClickAway from 'react-use/lib/useClickAway' // TODO: revert import change when this issue is resolved: https://github.com/streamich/react-use/issues/2074
import useWindowScroll from 'react-use/lib/useWindowScroll'
import { GoogleTagManager } from 'shared/trackers/google_tag_manager'
import { Hotjar } from 'shared/trackers/hotjar'
import {
  I18nHtml,
  JSONFetcher,
  LogoCompact,
  Modal,
  TrackingCookieType,
  themeColor,
  useAnalytics,
  useTrackingCookie,
} from 'shared'
import { TrackerProps } from 'shared/trackers/trackers'

export const TRACKING_COOKIE_NAME = 'betterplace-tracking-accepted'
export const utm_params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content']

const sendBrowserEvent = (event_name: string): void => {
  JSONFetcher.post({
    url: '/browser_events',
    body: { event_name: `${event_name}-v3` },
  })
}

function setUtmCookies(domain: string, params: URLSearchParams) {
  for (const name of utm_params) {
    const param = params.get(name)
    if (!param) continue
    cookies.set(`tracking_${name}`, param, {
      maxAge: 6 * 60 * 60,
      secure: true,
      sameSite: 'lax',
      domain: `.${domain}`,
    })
  }
}

const setTrackingCookie = (value: TrackingCookieType, domain: string) => {
  cookies.set(TRACKING_COOKIE_NAME, value, {
    path: '/',
    expires: new Date(2099, 1, 1),
    domain: `.${domain}`,
  })
  const searchParams = new URLSearchParams(window.location.search)
  if (value !== 'accepted' || !searchParams) return

  setUtmCookies(domain, searchParams)
}

export const CookieBanner = (props: TrackerProps): JSX.Element | null => {
  const trackingCookieValue = useTrackingCookie(cookies)
  const [showModal, setShowModal] = useState(false)
  const refCookieBanner = useRef(null)

  const currentDomain = useMemo(() => getUrl(), [])

  const acceptTracking = useCallback(() => {
    setTrackingCookie('accepted', currentDomain)
    sendBrowserEvent('tracking-accepted')
  }, [currentDomain])

  const rejectTracking = useCallback(() => {
    setTrackingCookie('rejected', currentDomain)
    sendBrowserEvent('tracking-rejected')
  }, [currentDomain])

  const openModal = useCallback(() => {
    setShowModal(true)
    sendBrowserEvent('tracking-more-information')
  }, [])

  useEffect(() => {
    sendBrowserEvent('tracking-banner-show')

    // remove outdated cookie information
    const trackingCookieValue = cookies.get(TRACKING_COOKIE_NAME)
    if (['yes', 'no', 'accepted'].includes(trackingCookieValue)) {
      cookies.remove(TRACKING_COOKIE_NAME, { path: '/' })
      if (trackingCookieValue === 'no') return
      setTrackingCookie('accepted', currentDomain)
    }
  }, [currentDomain])

  useEffect(() => {
    // if cookie already exists, remove and reset it for all sub domains
    // we only need this in legacy, because in the new stack there was no cookie banner until now
    // so there is no need to update anything
    if (trackingCookieValue) {
      cookies.remove(TRACKING_COOKIE_NAME, { path: '/' })
      setTrackingCookie(trackingCookieValue, currentDomain)
    }
  }, [currentDomain, trackingCookieValue])

  // if we do a/b test, we would need to pass environment as a parameter to this function
  // then we need to define what we want to do inside based on the environment
  // ( whether to run Posthog/analytics/etc.)
  useAnalytics(trackingCookieValue)

  // do not render in Rails.env.test
  if (props.environment === 'test') {
    return null
  }

  if (trackingCookieValue === 'accepted') {
    return (
      <>
        <GoogleTagManager {...props} />
        <Hotjar {...props} />
      </>
    )
  }

  if (trackingCookieValue === 'rejected') {
    return null
  }

  return (
    <div className="generic-cookie-banner bg-teal-700" ref={refCookieBanner}>
      <Autorejecter refCookieBanner={refCookieBanner} rejectTracking={rejectTracking} showModal={showModal} />
      <div className="cookie-content-wrapper">
        <Modal
          id="cookie-banner"
          show={showModal}
          onHide={() => setShowModal(false)}
          content={<I18nHtml i18nKey="layouts.cookie_banner.information_details_html" />}
          header={i18n.t('layouts.cookie_banner.information_headline')}
          size="modal-lg"
        />

        <div className="row">
          <div className="col-md-4 hidden desktop:block">
            <div className="flex justify-center" style={{ height: '100%' }}>
              <LogoCompact color={themeColor('teal-500')} style={{ width: 135 }} />
            </div>
          </div>
          <div className="col-md-18 px-4">
            <h2 className="text-xl desktop:text-5xl">{i18n.t('layouts.cookie_banner.headline')}</h2>
            <p>{i18n.t('layouts.cookie_banner.introduction_text')}</p>
            <p>{i18n.t('layouts.cookie_banner.call_for_action')}</p>
            <div className="flex my-3 justify-between flex-col desktop:flex-row">
              <div className="flex mb-3">
                <InfoButton className="flex-grow" onClick={openModal} />
              </div>
              <div className="flex flex-col-reverse desktop:flex-row button-wrapper">
                <RejectButton className="flex-grow mb-3" onClick={rejectTracking} />
                <AcceptButton className="flex-grow mb-3" onClick={acceptTracking} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * @desc Component to auto-reject cookies some time after page interaction.
 * must be unmounted to cancel rejection.
 * @TODO TODO: Refactor into a hook
 */
const Autorejecter = ({
  refCookieBanner,
  rejectTracking,
  showModal,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refCookieBanner: React.MutableRefObject<any>
  rejectTracking: () => void
  showModal: boolean
}): null => {
  const [pageUsed, setPageUsed] = useState(false)
  const { y } = useWindowScroll()
  const refFirstScroll = useRef(true)

  // track scrolling on page
  useEffect(() => {
    if (refFirstScroll.current) {
      refFirstScroll.current = false
      return
    }
    setPageUsed(true)
  }, [y, setPageUsed])

  // track clicks outside cookie banner
  useClickAway(refCookieBanner, () => setPageUsed(true))

  // if either happened, start timeout to reject tracking
  useEffect(() => {
    if (!pageUsed || showModal) return
    const timer = setTimeout(() => rejectTracking(), 10000)
    return () => clearTimeout(timer)
  }, [pageUsed, showModal, rejectTracking])

  return null
}

export const CookieBannerLink = ({ className, content }: { className?: string; content?: string }): JSX.Element => {
  const currentDomain = useMemo(() => getUrl(), [])

  /** @type {React.MouseEventHandler} */
  const handleClick: React.MouseEventHandler = (event) => {
    event.preventDefault()
    setTrackingCookie('edit', currentDomain)
  }

  return (
    <button className={className ?? ''} onClick={handleClick}>
      {content}
    </button>
  )
}

const AcceptButton = ({
  className,
  ...props
}: { className?: string } & React.HTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <button className={classnames('btn btn-primary btn-large', className)} {...props}>
    <i className="fas fa-check" /> <span>{i18n.t('layouts.cookie_banner.accept_button')}</span>
  </button>
)

const RejectButton = ({
  className,
  ...props
}: { className?: string } & React.HTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <button className={classnames('btn btn-outline btn-large', className)} {...props}>
    {i18n.t('layouts.cookie_banner.reject_button')}
  </button>
)

const InfoButton = ({
  className,
  ...props
}: { className?: string } & React.HTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <button className={classnames('simulated-link', className)} {...props}>
    {i18n.t('layouts.cookie_banner.information_button')}
  </button>
)

function getUrl() {
  const hostname = window.location.hostname
  if (!hostname) return ''
  const hostnameArray = hostname.split('.')
  if (hostnameArray.length <= 2) return hostname

  return hostnameArray.slice(1).join('.')
}
